<template>
  <div>
    <base-dialog :show="showEditDialog" icon="mdi-account-edit" formTitle="Edit Profile" v-on:close="close" v-on:save="save">
      <template v-slot:form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              NAME <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-text-field
              v-model="data.fullName"
              :rules="[v => !!v || 'This field is required']"
              placeholder="Enter your name"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="12" md="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              EMAIL <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-text-field
              v-model="data.email"
              placeholder="Enter your email"
              :rules="[rules.email]"
              outlined
              clearable
              dense
            />
          </v-col>
          <v-col
            cols="12"
            class="my-0 py-0"
          >
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              PHONE <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-text-field
              v-model="data.phone"
              :rules="phoneRules"
              placeholder="Enter your phone number"
              outlined
              dense
            />
          </v-col>
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>

<script>
  import BaseDialog from '@/components/dialog/BaseDialog'
  import UserService from '@/services/user.service'
  import snackbarPlugin from '@/plugins/snackbar'

  export default {
    components: {
      BaseDialog,
    },
    props: ['showEditDialog', 'data'],
    data () {
      return {
        valid: true,
        phoneRules: [
          v => !v || 'Phone is required',
          v => /^[/+]?[(]?[0-9]{3}[)]?[-/s/.]?[0-9]{3}[-/s/.]?[0-9]{4,6}$/im.test(v) || 'Phone must be valid',
        ],
        rules: {
          email: (value) => {
            const pattern =
              /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail'
          },
        },
      }
    },
    methods: {
      close () {
        this.$emit('update:show-edit-dialog', false)
      },
      async save () {
        if (this.$refs.form.validate()) {
          snackbarPlugin.showLoading('Updating ...')
          await UserService.putMyProfile({
            fullName: this.data.fullName,
            email: this.data.email,
            phone: this.data.phone,
          }).then(() => {
            snackbarPlugin.showSuccess('Your profile was updated!')
            UserService.getProfile().then((profile) => { this.$store.commit('auth/setProfile', profile) })
          }).catch((error) => {
            //console.log(error)
          })
          this.close()
        }
        if (!this.$refs.form.validate()) {
          snackbarPlugin.showError('Oops, complete the required fields!')
        }
      },
    },
  }
</script>
